import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import About from '@/components/About'
import PrivacyPolicy from  '@/components/PrivacyPolicy'
import TermsOfServices from  '@/components/TermsOfServices'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    { path: '/plants',
      redirect: { name: 'home', query: {} }},
    {
      path:'/about',
      name: 'about',
      component: About,
    },
    {
      path:'/privacy-policy',
      name: 'privacy-policy',
      component: PrivacyPolicy,
    },
    {
      path:'/terms-of-services',
      name: 'terms-of-services',
      component: TermsOfServices,
    },
  ]
});

export default router;
