<template>
  <b-container>
    <b-col class="vh-100 text-center" align-v="center">
      <b-img
        :src="require('./../assets/logo.png')"
        fluid
        alt="Plantitas logo"
      ></b-img>
      <h3>Welcome to Plantitas!</h3>
      <p>(Work in progress)</p>
      <a
        href="https://play.google.com/store/apps/details?id=com.hermosodev.plantitas&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <b-img
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          fluid
          alt="Get it on Google Play"
        ></b-img>
      </a>
    </b-col>
  </b-container>
</template>

<script>
export default {
  name: "Home",
};
</script>